import { ComponentType, useEffect, useRef, useState } from 'react';

import { RootState } from 'redux/store';
import { hasNavigatedToPage } from 'concepts/router';
import { connect, ConnectedProps } from 'react-redux';
import { getSiteLocale, getSiteUrl } from 'concepts/site';
import useSections from 'hooks/api/useSections';
import useSiteId from 'hooks/useSiteId';
import { pathToDisplay } from 'services/routes';

import UnsavedChangesModal from '../UnsavedChangesModal';

import WallEditForm from './components/WallEditForm';
import { EmbedScreenEditFormComponentProps, EmbedScreenEditFormValues } from './types';
import SaveButton from 'components/SaveButton';
import styles from './EmbedScreenEditForm.module.scss';
import { FormError } from '../EmbedForm';
import Icon from 'components/Icon';
import { FormikProps } from 'formik';
import { getEmbedScreenUpdateError } from 'concepts/embed-screen';
import { useEventListener } from 'usehooks-ts';
import { showSuccess } from 'concepts/push-notifications';
import { useHistory } from 'react-router-dom';

interface EmbedScreenEditFormProps extends ConnectedProps<typeof connector> {
  formik: FormikProps<EmbedScreenEditFormValues>;
  embedUrlAliasEnabled: boolean;
}

const EmbedScreenEditForm = ({
  defaultLocale,
  embedScreenUpdateError,
  formik,
  isNavigatedToPage,
  siteUrl,
  embedUrlAliasEnabled,
}: EmbedScreenEditFormProps) => {
  const [isNavigationConfirmVisible, setNavigationConfirmVisible] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const siteId = useSiteId();
  const { sections } = useSections(siteId, { pageSize: null });
  const history = useHistory();

  const { values, submitForm, isSubmitting, dirty, isValid, handleSubmit } = formik;

  // Scroll to top of the form on mount
  useEffect(() => {
    if (formRef?.current?.offsetTop) {
      window.scrollTo(0, formRef.current.offsetTop - 30);
    }
  }, [formRef]);

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (formRef && event.key === 's' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      submitForm().then(() => {
        showSuccess('Saved changes');
      });
    }
  });

  const closePage = () => {
    if (isNavigatedToPage) {
      history.goBack();
    } else {
      history.push(pathToDisplay(siteUrl));
    }
  };

  const hasSaveError = !!embedScreenUpdateError;
  const hasValidationError = !isValid && dirty;

  const FormComponent = getFormComponent(values.embed.style);

  return (
    <>
      <UnsavedChangesModal
        isVisible={isNavigationConfirmVisible}
        onClose={() => {
          setNavigationConfirmVisible(false);
        }}
        onConfirm={() => {
          setNavigationConfirmVisible(false);
          closePage();
        }}
        onSave={() => {
          submitForm().then(() => {
            setNavigationConfirmVisible(false);
            closePage();
          });
        }}
        isSaving={isSubmitting}
        isValid={isValid}
      />
      <form onSubmit={handleSubmit} ref={formRef}>
        {FormComponent && (
          <FormComponent
            formik={formik}
            defaultLocale={defaultLocale}
            embedScreenUpdateError={embedScreenUpdateError}
            sections={sections}
            embedUrlAliasEnabled={embedUrlAliasEnabled}
          />
        )}

        <div className="flex flex-wrap items-center gap-y-4 space-x-4 sm:flex-nowrap">
          <SaveButton type="submit" isSaving={isSubmitting} hasError={hasSaveError} style={{ minWidth: '8rem' }}>
            Save changes
          </SaveButton>

          <button
            type="button"
            className={styles.linkButton}
            onClick={() => {
              if (dirty && !isSubmitting) {
                setNavigationConfirmVisible(true);
                return;
              }

              closePage();
            }}
          >
            Close
          </button>

          {(hasSaveError || hasValidationError) && (
            <FormError className="!inline-flex animate-fade-in items-center space-x-2 opacity-0">
              <Icon type="warning" />
              <span>
                {hasSaveError
                  ? 'Failed to save digital screen, please check for errors.'
                  : 'Unable to save digital screen, please check for errors.'}
              </span>
            </FormError>
          )}
        </div>
      </form>
    </>
  );
};

const getFormComponent = (
  type: EmbedStyleType | undefined
): ComponentType<EmbedScreenEditFormComponentProps> | null => {
  switch (type) {
    case 'wall_v2':
      return WallEditForm;
    default:
      return null;
  }
};

const mapStateToProps = (state: RootState) => ({
  siteUrl: getSiteUrl(state),
  defaultLocale: getSiteLocale(state),
  isNavigatedToPage: hasNavigatedToPage(state),
  embedScreenUpdateError: getEmbedScreenUpdateError(state),
});

const connector = connect(mapStateToProps);

export default connector(EmbedScreenEditForm);
