import {
  Field,
  Input
} from '../../EmbedForm';
import Label from 'components/Label';
import React, { useEffect } from "react";

const EmbedUrlAliasField: React.FC<{
  value: string;
  onChange: (value: string) => void;
  nameValue: string;
}> = ({ value, onChange, nameValue }) => {

  const sanitizeUrlAlias = (input: string) => {
    return input
      .toLowerCase()
      .replace(/[^a-z0-9-]+/g, (match, index, str) => {
        // Replace non-standard characters with "-" only if the previous character is not already "-"
        return str[index - 1] === "-" ? "" : "-";
      });
  };

  useEffect(() => {
    if (value) {
      onChange(sanitizeUrlAlias(value));
    }
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = sanitizeUrlAlias(event.target.value);
    onChange(sanitizedValue);
  };

  const handleGenerateAlias = () => {
    onChange(sanitizeUrlAlias(nameValue));
  };

  return (
    <Field>
      <div>
        <Label htmlFor="url_alias" className='!inline-block !w-auto !align-middle'>
          Url Alias
        </Label>
        <button type="button" onClick={handleGenerateAlias}
          className='align-middle ml-2 font-normal inline-block text-brand hover:underline text-xs mt-[3px]'>
          Generate from Name
        </button>
      </div>
      <Input
        id="url_alias"
        name="url_alias"
        type="text"
        onChange={handleInputChange}
        value={value}
      />
    </Field>
  );
};

export default EmbedUrlAliasField;
