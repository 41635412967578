import { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import AddToWordPressModal from '../AddToWordPressModal';
import Icon from 'components/Icon';
import styles from './DisplayListItem.module.scss';
import {
  formatEmbedCodeMailtoLink,
  formatEmbedScreenUrlMailtoLink,
  formatSlideshowUrlMailtoLink,
} from 'services/display-mailto-link';
import EmbedCodeCopier from '../EmbedCodeCopier';
import { pathToEmbed, pathToEmbedScreen, pathToSlideshow } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';
import useSiteUuid from 'hooks/useSiteUuid';
import DisplayListPreview from '../DisplayListPreview';

type DisplayListItemProps = {
  display: DisplayListItem;
  onRemove: any;
  sections: Section[];
  embedUrlAliasEnabled: boolean;
};

const getSectionNames = (display: DisplayListItem, sections: Section[]) => {
  const sectionIds = display.sectionIds;

  if (!sectionIds) {
    return null;
  }

  return sectionIds.reduce((names: string[], sectionId: number) => {
    const section = sections.find((section) => section.id === sectionId);

    return section ? [...names, section.name] : names;
  }, []);
};

const getEditLink = (display: DisplayListItem, siteUrl: string) => {
  switch (display.itemType) {
    case 'screen':
      return pathToEmbedScreen(siteUrl, display.uuid);
    case 'slideshow':
      return pathToSlideshow(siteUrl, display.uuid);
    case 'embed':
    default:
      return pathToEmbed(siteUrl, display.uuid);
  }
};

const getMailToLink = (display: DisplayListItem) => {
  switch (display.itemType) {
    case 'screen':
      return formatEmbedScreenUrlMailtoLink(display.styleName, display.previewUrl);
    case 'slideshow':
      return formatSlideshowUrlMailtoLink(display.previewUrl);
    case 'embed':
    default:
      return formatEmbedCodeMailtoLink(display.styleName, display.embedCode);
  }
};

const ListItem = ({ display, onRemove, sections, embedUrlAliasEnabled }: DisplayListItemProps) => {
  const [showAddToWordPressModal, setShowAddToWordPressModal] = useState(false);

  const siteUrl = useSiteUrl();
  const siteUuid = useSiteUuid();
  const sectionNames = getSectionNames(display, sections);

  const isEmbedScreen = display.itemType === 'screen';
  const isSlideshow = display.itemType === 'slideshow';
  const isScreen = isEmbedScreen || isSlideshow;

  const editLink = getEditLink(display, siteUrl);
  const mailToLink = getMailToLink(display);

  return (
    <div className={styles.item} key={display.uuid}>
      <Link to={editLink} className={classnames(styles.preview, { [styles.slideshowPreview]: isScreen })} tabIndex={-1}>
        <DisplayListPreview display={display} />
      </Link>
      <div className={styles.content}>
        <Link to={editLink} className={styles.name}>
          {display.name}
          {display.itemType === 'embed' && display.demo && (
            <span className={styles.demo} aria-hidden="true" title="Created during the demo">
              Demo
            </span>
          )}
        </Link>

        <button
          onClick={() => onRemove(display)}
          className={styles.removeButton}
          type="button"
          aria-label="Remove this embed code"
          title="Remove this embed code"
        >
          <Icon type="remove-circle" />
        </button>

        <div className={styles.details}>
          <div className={styles.detail}>
            <span className={styles.detailTitle}>Style</span>
            <span className={classnames(styles.detailValue, 'notranslate')}>{display.styleName}</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.detailTitle}>Displaying content from</span>
            <span className={styles.detailValue}>
              {sectionNames?.length ? <span className="notranslate">{sectionNames.join(', ')}</span> : 'All sections'}
            </span>
          </div>
        </div>

        {isScreen ? (
          <div className={styles.slideshowUrl}>
            <a className={styles.slideshowUrlLink} target="_blank" rel="noopener noreferrer" href={display.previewUrl}>
              {display.previewUrl}
              <Icon className={styles.slideshowUrlLinkIcon} type="external-link" />
            </a>
          </div>
        ) : (
          <EmbedCodeCopier
            embedCode={display.embedCode}
            siteUuid={siteUuid}
            embedUuid={display.uuid}
            embedStyle={display.styleName}
            embedConfig={display.configuration}
            embedUrlAliasEnabled={embedUrlAliasEnabled}
            embedUrlAlias={display.url_alias}
            siteUrl={siteUrl}
          />
        )}

        <div className={styles.actions}>
          <Link to={editLink} className={classnames(styles.actionButton, styles.primaryActionButton)}>
            <Icon type="customize" className={styles.actionIcon} />
            Edit style and settings
          </Link>
          <a className={styles.actionButton} target="_blank" rel="noopener noreferrer" href={display.previewUrl}>
            <Icon type="eye-circle" className={styles.actionIcon} />
            {isScreen ? 'View in full' : 'Preview'}
          </a>
          <a className={styles.actionButton} target="_blank" rel="noopener noreferrer" href={mailToLink}>
            <Icon type="email-circle" className={styles.actionIcon} />
            {isScreen ? `Send ${display.styleName} URL via email` : 'Send via email'}
          </a>

          {!isScreen && (
            <button className={styles.actionButton} onClick={() => setShowAddToWordPressModal(true)}>
              <Icon type="wordpress-circle" className={styles.actionIcon} />
              Add to WordPress
            </button>
          )}
        </div>
      </div>

      {showAddToWordPressModal && display.uuid && siteUuid && (
        <AddToWordPressModal
          siteUuid={siteUuid}
          embedUuid={display.uuid}
          dismissAction={() => {
            setShowAddToWordPressModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ListItem;
