import { useState } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import highlightStyle from 'react-syntax-highlighter/dist/esm/styles/prism/coy';
import classnames from 'classnames';
import CopyButton from 'components/CopyButton';
import EmbedMailtoLink from '../EmbedMailtoLink';
import IframeCopyModal from '../IframeCopyModal';
import copyToClipboard from 'services/copy-to-clipboard';
import {
  getLongEmbedIframeCode,
  DEFAULT_IFRAME_HEIGHT,
  DEFAULT_CAROUSEL_IFRAME_HEIGHT,
} from 'services/embed-iframe-code';

import styles from './EmbedCodePreview.module.scss';
import useCarouselStyle from 'pages/display/hooks/useCarouselStyle';

function embedCodeToString(
  embedUrl: string,
  embedUuid: string,
  embedUrlAliasEnabled: boolean,
  embedUrlAlias: string,
  embedAliasedUrl: string
) {
  return embedUrlAliasEnabled
    ? `<div data-flockler-embed="${embedUrlAlias}"></div>\n<script src="${embedAliasedUrl}" async></script>`
    : `?<div id="flockler-embed-${embedUuid}"></div>\n<script src="${embedUrl}" async></script>`;
}

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('jsx', jsx);

type EmbedCodePreviewProps = {
  embedStyleName: string;
  embedUrl: string;
  embedUuid: string;
  embedCodeRef: any;
  siteUuid: string;
  embedConfig: EmbedConfiguration | SlideshowConfiguration;
  embedUrlAliasEnabled: boolean;
  siteUrl: string;
  embedUrlAlias: string;
  embedAliasedUrl: string;

  isIframeCopyModalOpen: boolean;
  setIsframeCopyModalOpen: (isOpen: boolean) => void;

  selectedCodeType: EmbedCodeType;
  setSelectedCodeType: (type: EmbedCodeType) => void;
};

const EmbedCodePreview = ({
  embedStyleName,
  embedUrl,
  embedCodeRef,
  embedUuid,
  embedUrlAlias,
  embedAliasedUrl,
  embedConfig,
  embedUrlAliasEnabled,

  isIframeCopyModalOpen,
  setIsframeCopyModalOpen,

  selectedCodeType,
  setSelectedCodeType,
  siteUuid,
  siteUrl,
}: EmbedCodePreviewProps) => {
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState(false);
  const carouselStyle = useCarouselStyle(embedConfig);
  const embedCode = embedCodeToString(embedUrl, embedUuid, embedUrlAliasEnabled, embedUrlAlias, embedAliasedUrl);
  const copyEmbedCode = () => copyToClipboard(embedCodeRef, setCopiedMessageVisible);

  return (
    <>
      {isIframeCopyModalOpen && (
        <IframeCopyModal
          dismissAction={() => setIsframeCopyModalOpen(false)}
          embedStyle={embedStyleName}
          embedUuid={embedUuid}
          siteUuid={siteUuid}
          embedConfig={embedConfig}
          embedUrlAliasEnabled={embedUrlAliasEnabled}
          embedUrlAlias={embedUrlAlias}
          siteUrl={siteUrl}
        />
      )}
      <div className={styles.wrap}>
        <div className={styles.tabs}>
          <button
            onClick={() => setSelectedCodeType('default')}
            className={classnames(styles.tab, styles.default, { [styles.selected]: selectedCodeType === 'default' })}
          >
            Default
          </button>
          <button
            onClick={() => setSelectedCodeType('iframe')}
            className={classnames(styles.tab, { [styles.selected]: selectedCodeType === 'iframe' })}
          >
            iframe
          </button>
        </div>

        {selectedCodeType === 'default' && (
          <div ref={embedCodeRef} className={classnames(styles.embedCode, 'notranslate')} onClick={copyEmbedCode}>
            <SyntaxHighlighter language="jsx" style={highlightStyle}>
              {embedCode}
            </SyntaxHighlighter>
          </div>
        )}
        {selectedCodeType === 'iframe' && embedUuid && (
          <div
            className={classnames(styles.embedCode, styles.iframeEmbedCode, 'notranslate')}
            onClick={() => setIsframeCopyModalOpen(true)}
          >
            <SyntaxHighlighter language="jsx" style={highlightStyle}>
              {getLongEmbedIframeCode(
                siteUuid,
                embedUuid,
                embedUrlAliasEnabled,
                siteUrl,
                embedUrlAlias,
                embedStyleName === 'Carousel'
                  ? (carouselStyle?.height ?? DEFAULT_CAROUSEL_IFRAME_HEIGHT)
                  : DEFAULT_IFRAME_HEIGHT
              )}
            </SyntaxHighlighter>
          </div>
        )}

        <div className={styles.actions}>
          {selectedCodeType === 'default' && (
            <CopyButton isCopiedMessageVisible={isCopiedMessageVisible} onClick={copyEmbedCode}>
              Copy the Embed code
            </CopyButton>
          )}
          {selectedCodeType === 'iframe' && (
            <CopyButton isCopiedMessageVisible={isCopiedMessageVisible} onClick={() => setIsframeCopyModalOpen(true)}>
              Copy the iframe code
            </CopyButton>
          )}

          <EmbedMailtoLink embedCode={embedCode} embedStyleName={embedStyleName} />
        </div>
      </div>
    </>
  );
};

export default EmbedCodePreview;
